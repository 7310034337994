import { getMappedBackendQueryLanguage } from "../language/backend.language";
import { DefaultUILanguage } from "../language/lang.config";

export namespace ApiDataHelper {
  export function joinTwoTables(
    rows: OfferExtType[],
    lang: string,
    // 如果lang没有对应的数据，是否返回默认语言的数据
    useLangFallback: boolean = true
  ) {
    const langFallback = getMappedBackendQueryLanguage(DefaultUILanguage);

    /* 后端返回的是一个列表，把offer以及offer_trans都列出来了，我们要匹配此格式 */
    let row_offer_trans = {} as OfferExtType;
    let row_offer = {};
    rows?.forEach((w) => {
      if (w.lang === lang && w.subjectTrans) {
        row_offer_trans = { ...w };
      } else if (w.subject && w.offerId) {
        row_offer = { ...w };
      }
    });

    // 如果找不到lang对应的数据，则找langFallback对应的数据
    !row_offer_trans.offerId &&
      useLangFallback &&
      rows?.forEach((w) => {
        if (w.lang === langFallback && w.subjectTrans) {
          row_offer_trans = { ...w };
        }
      });

    const ret = {
      subjectTrans: row_offer_trans.subjectTrans,
      offerData: row_offer_trans.offerData,
      lang: row_offer_trans.lang,
      ...row_offer,
    };
    return ret as OfferExtType;
  }
}

/**
 * 将 CategoryType 数据转换为 MenuItem 格式
 * @param categories CategoryType 数组
 * @returns MenuItem 数组
 */
export function convertCategoriesToMenuItems(
  categories: CategoryType[]
): MenuItem[] {
  const top = categories.filter((category) => !category.parentId);
  const ret = top.map((w) => {
    const second = categories.filter((x) => x.parentId === w.categoryId);
    const ret2 = second.map((a) => {
      const third = categories
        .filter((b) => b.parentId === a.categoryId)
        .map((c) => ({
          key: c.categoryId.toString(),
          label: c.name || "",
          collectionId: `${c.collectionId || ""}`,
          externalId: `${c.externalId || ""}`,
        }));
      return {
        key: `${a.categoryId}`,
        label: a.name || "",
        collectionId: `${a.collectionId || ""}`,
        externalId: `${a.externalId || ""}`,
        children: third.length ? third : undefined,
      };
    });
    return {
      key: `${w.categoryId}`,
      label: w.name || "",
      collectionId: `${w.collectionId || ""}`,
      externalId: `${w.externalId || ""}`,
      children: ret2,
    };
  });
  return ret;
}

/**
 * 从MenuItem数组获取所有叶子结点
 */
export function convertMenuItems2FlatCategories(items: MenuItem[]) {
  let list = [] as MenuItem[];
  items.forEach((category) => {
    if (category.children?.length) {
      const categories2 = category.children;
      categories2.forEach((category2) => {
        if (category2.children?.length) {
          list = [...list, ...category2.children];
        } else {
          list.push(category2);
        }
      });
    } else {
      list.push(category);
    }
  });
  return list;
}

/**
 * 获取并合并相应语言信息到 MenuItem 数组中
 * @param menuItems MenuItem 数组
 * @param translations CategoryTransType 数组
 * @param lang 语言
 * @returns 合并了语言信息的 MenuItem 数组
 */
export function mergeTranslationsToMenuItems(
  menuItems: MenuItem[],
  translations: CategoryTransType[],
  lang: string
): MenuItem[] {
  const translationMap = new Map<number, string>();

  translations.forEach((translation) => {
    if (translation.lang === lang) {
      translationMap.set(translation.categoryId, translation.description || "");
    }
  });

  function mergeTranslations(items: MenuItem[]): void {
    items.forEach((item) => {
      const description = translationMap.get(parseInt(item.key));
      if (description) {
        item.label = description;
      }
      if (item.children) {
        mergeTranslations(item.children);
      }
    });
  }

  mergeTranslations(menuItems);
  return menuItems;
}

export function translateCategories(
  items: MenuItem[],
  categoriesTrans: CategoryTransType[],
  language: string
): MenuItem[] {
  return items.map((w) => ({
    ...w,
    label:
      categoriesTrans.find(
        (x) => `${x.categoryId}` === w.key && x.lang === language
      )?.description || w.label,
    children: w.children
      ? translateCategories(w.children, categoriesTrans, language)
      : undefined,
  }));
}
