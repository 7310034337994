import { Dropdown, MenuProps } from "antd";
import { useTypedSelector } from "../lib/state/store";
import { DollarOutlined } from "@ant-design/icons";
import { DefaultExchangeSourceLanguage } from "../lib/language/lang.config";

function CurrencyExchange() {
  const languages = useTypedSelector((state) => state.lang).languages;
  const defaultLanguage = languages.find(
    (w) => w.lang === DefaultExchangeSourceLanguage
  );

  const items: MenuProps["items"] = languages
    .filter((l) => l.status === "1")
    .map(({ currency, exchange, lang }) => ({
      key: lang,
      label: (
        <div className="flex flex-nowrap item-center">
          1{defaultLanguage?.currency} = {exchange}
          {currency}
        </div>
      ),
    }))
    .filter((w) => w.key !== DefaultExchangeSourceLanguage);

  return (
    <Dropdown menu={{ items }}>
      <a
        onClick={(e) => e.preventDefault()}
        className="pointer flex items-center cursor-pointer font-bold a-nohover"
      >
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
        >
          <g id="SVGRepo_iconCarrier">
            <path
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
            ></path>
            <path
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 11h4a2 2 0 0 0 2-2v0a2 2 0 0 0-2-2h-4v4zm0 0v3m0-3H8m2 6v-3m0 0H8m2 0h3"
            ></path>
          </g>
        </svg>
      </a>
    </Dropdown>
  );
}

export default CurrencyExchange;
