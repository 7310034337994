import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { WebSocketService } from "./websocket.service";
import { useTypedSelector } from "../state/store";
import Cookies from "js-cookie";
import { useAppWindow } from "../hooks/window";
import { useDispatch } from "react-redux";
import { setMessage } from "../state/websocket.slice";

interface WebSocketContextProps {
  wsService: WebSocketService | null;
  closeWs: () => void;
}

export const WebSocketContext = createContext<WebSocketContextProps>({
  wsService: null,
  closeWs: () => {},
});

interface WebSocketProviderProps {
  children: React.ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  children,
}) => {
  const [sessionId, setSessionId] = useState<string | undefined>(
    Cookies.get("session_id")
  );
  const appWindow = useAppWindow();
  // 这里通过state来保存对appWindow.globalSocket的引用
  // 直接引用appWindow.globalSocket不会监听globalSocket的变化
  const [globalSocket, setGlobalSocket] = useState<WebSocketService | null>(
    null
  );

  const dispatch = useDispatch();

  // console.log("sessionId", sessionId);

  useEffect(() => {
    // 检测cookie的变动
    const observer = new MutationObserver(() => {
      setSessionId(Cookies.get("session_id"));
    });

    observer.observe(document, {
      attributes: true,
      attributeFilter: ["cookie"],
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  const userId = useTypedSelector((state) => state.user.user?.userId);
  if (!process.env.NEXT_PUBLIC_API_WS_URL) {
    throw new Error("请先配置环境变量NEXT_PUBLIC_API_WS_URL");
  }

  useEffect(() => {
    if (!sessionId) return;

    const wsUrl = `${process.env.NEXT_PUBLIC_API_WS_URL}/${sessionId}/${
      userId || "anoymous"
    }`;
    if (appWindow) {
      if (
        !appWindow.globalSocket ||
        // 登录或者登出时重新连接
        appWindow.globalSocket.getUrl() !== wsUrl
      ) {
        if (appWindow.globalSocket) {
          appWindow.globalSocket.isReconnRequired = false;
          appWindow.globalSocket.close();
          dispatch(setMessage(null));
        }
        console.log("建立websocket连接", wsUrl);
        appWindow.globalSocket = new WebSocketService(wsUrl);
      }
      setGlobalSocket(appWindow.globalSocket);
    }
  }, [userId, sessionId, appWindow, dispatch]);

  const handleClose = () => {
    if (globalSocket) {
      globalSocket.isReconnRequired = false;
      globalSocket.close();
      dispatch(setMessage(null));
    }
  };

  // TODO 更新Context为serviceworker

  return (
    <WebSocketContext.Provider
      value={{ wsService: globalSocket, closeWs: handleClose }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
