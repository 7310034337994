"use client";

import { ShoppingCartOutlined, UserOutlined } from "@ant-design/icons";
import "./header.css";
import Link from "next/link";
import { Badge, Dropdown, Typography } from "antd";

import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./language.switcher";
import CurrencyExchange from "./currency.exchange";
import { StaticLinks } from "../lib/route/link";
import { useContext, useEffect, useMemo, useState } from "react";
import { syncDataToAllBrowserTabs, useTypedSelector } from "../lib/state/store";
import { userApi } from "../lib/api/user";
import { useDispatch } from "react-redux";
import { setToken, setUserProfile } from "../lib/state/user.slice";
import { usePathname, useRouter } from "next/navigation";
import { apiClient } from "../lib/util/http";
import { useWebSocketMessages } from "../lib/ws/useWebsocketMessages";
import { WebSocketContext } from "../lib/ws/websocket.context";

const { Text } = Typography;

function TopNav() {
  const router = useRouter();
  const dispatch = useDispatch();
  const pathname = usePathname();
  const { t } = useTranslation("translation");
  const user = useTypedSelector((state) => state.user);
  const [cartNumber, setCartNumber] = useState(0);

  const { message } = useWebSocketMessages();
  const { closeWs } = useContext(WebSocketContext);

  useEffect(() => {
    console.log("messgaes from websocket", message);
    const item = message;
    if (item?.cart_info && Array.isArray(item?.cart_info)) {
      const n = item.cart_info.length;
      setCartNumber(n);
    } else {
      setCartNumber(0);
    }
  }, [message]);

  // 退出
  const logOut = async () => {
    await userApi.logout();
    dispatch(setUserProfile(null));
    dispatch(setToken({ token: "" }));
    apiClient.setToken("");
    syncDataToAllBrowserTabs();
    // 关闭websocket连接
    closeWs();
    router.push(StaticLinks.Login);
  };

  const userItems = useMemo(() => {
    return [
      {
        key: "logout",
        label: (
          <Link
            href=""
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              logOut();
            }}
            className="a-nohover"
          >
            <div className="flex flex-nowrap item-center first-letter:uppercase">
              {t("logout")}
            </div>
          </Link>
        ),
      },
    ];
  }, [t]);

  return (
    <>
      {user?.user ? (
        <>
          <span className="text-xs">{t("welcome")}</span>
          <Dropdown
            menu={{
              items: userItems,
            }}
          >
            <Link
              href=""
              onClick={(e) => e.preventDefault()}
              className="pointer flex items-center cursor-pointer a-nohover"
            >
              <UserOutlined style={{ fontSize: "20px" }} />
              <span className="first-letter:uppercase strong ml-1 font-bold">
                {user.user?.userName}
              </span>
            </Link>
          </Dropdown>
        </>
      ) : (
        <>
          <Link
            href={`${
              pathname.includes(StaticLinks.Login)
                ? StaticLinks.Login
                : StaticLinks.LoginWithRedirect + pathname
            }`}
            className="font-bold"
          >
            {t("login")}
          </Link>
          <Link
            className="font-bold registerLink"
            href={`${StaticLinks.User}/register`}
          >
            {t("register")}
          </Link>
        </>
      )}
      <Link
        href={`${StaticLinks.Order}?menuid=11`}
        title={t("shoppingCart")}
        className="flex items-center font-bold a-nohover"
      >
        <Badge
          count={cartNumber}
          color={"primary"}
          overflowCount={99}
          size="small"
        >
          {/* <ShoppingCartOutlined
            style={{
              marginRight: cartNumber > 0 ? "8px" : "0px",
              fontSize: "20px",
              fontWeight: "bold",
            }}
            // className="cart-icon"
          /> */}
          <svg
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginRight: cartNumber > 0 ? "8px" : "0px" }}
          >
            <g id="SVGRepo_iconCarrier">
              <path
                d="M6.29977 5H21L19 12H7.37671M20 16H8L6 3H3M9 20C9 20.5523 8.55228 21 8 21C7.44772 21 7 20.5523 7 20C7 19.4477 7.44772 19 8 19C8.55228 19 9 19.4477 9 20ZM20 20C20 20.5523 19.5523 21 19 21C18.4477 21 18 20.5523 18 20C18 19.4477 18.4477 19 19 19C19.5523 19 20 19.4477 20 20Z"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </g>
          </svg>
        </Badge>
      </Link>
      <CurrencyExchange />
      <LanguageSwitcher />
    </>
  );
}

export default TopNav;
