export namespace StaticLinks {
  export const Homepage = "/";
  export const Category = "/search1688?categoryId=";
  export const SearchCollection = "/search1688?productCollectionId=";
  export const Search = "/search1688";
  export const SearchPartner = "/search/partner/";
  export const SearchAllPartners = "/search/partner";
  export const TopRank = "/toprank";
  // imageAddress必须是1688的产品图片
  export const SearchImageFrom1688 = "/search1688?imageAddress=";
  export const SearchImageUpload = "/search1688?imageId=";
  export const Offer = "/offer";
  export const User = "/user";
  export const Login = "/user/login";
  export const LoginWithRedirect = "/user/login?redirectTo=";
  export const Order = "/user/order";
  export const OrderShippingAddress = "/user/order?menuid=31";
}
