"use client";
import React from "react";
import { useTranslation } from "react-i18next";
import { StaticLinks } from "../lib/route/link";
import { RiseOutlined } from "@ant-design/icons";

type SearchHistoryProps = {
  recentSearches: string[];
  topSearches: string[];
};
const SearchHistory = ({ topSearches, recentSearches }: SearchHistoryProps) => {
  const { t } = useTranslation();
  const filteredRecentSearches = Array.from(new Set(recentSearches));

  return topSearches.length > 0 || recentSearches.length > 0 ? (
    <div style={{ padding: "20px" }} className="flex flex-col gap-2">
      <span>{t("top_searches")}</span>
      <div className="flex flex-row gap-2 flex-wrap">
        {topSearches.map((item, index) => (
          <a href={`${StaticLinks.Search}?subject=${item}`} key={item}>
            <span className="rounded-none px-2 py-1 border border-primary-200">
              {index < 5 && (
                <>
                  <RiseOutlined />{" "}
                </>
              )}
              {item}
            </span>
          </a>
        ))}
      </div>
      <span> </span>
      <span>{t("searches_history")}</span>
      <div className="flex flex-row gap-2 flex-wrap">
        {filteredRecentSearches.map((item) => (
          <a href={`${StaticLinks.Search}?subject=${item}`} key={item}>
            <span className="rounded-none px-2 py-1 border border-primary-200">
              {item}
            </span>
          </a>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SearchHistory;
