import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TestSliceType = {
  value: number;
};

export const testSlice = createSlice({
  name: "test",
  initialState: {
    value: 0,
  },
  reducers: {
    increment: (state, action: PayloadAction<string>) => {
      state.value += parseInt(action.payload) || 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
  },
});

export const { increment, decrement } = testSlice.actions;

export default testSlice.reducer;
