import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type UserSliceType = {
  token: string;
  user: UserProfile | null;
};

export const userSlice = createSlice({
  name: "user",
  initialState: {
    token: "",
    user: null,
  } as UserSliceType,
  reducers: {
    setToken: (state, action: PayloadAction<{ token: string }>) => {
      state.token = action.payload.token;
    },
    setUserProfile: (state, action: PayloadAction<UserProfile | null>) => {
      state.user = action.payload;
    },
  },
});

export const { setToken, setUserProfile } = userSlice.actions;

export default userSlice.reducer;
