"use client";
import { useEffect, useState } from "react";
import { WebSocketService } from "../ws/websocket.service";

type WindowType =
  | (Window & typeof globalThis & { globalSocket: WebSocketService })
  | null;
export function useAppWindow() {
  const [appWindow, setAppWindow] = useState<WindowType | null>(null);
  useEffect(() => {
    // 注意：只能在useEffect中使用window对象
    setAppWindow(window as WindowType);
  }, []);
  return appWindow;
}
