"use client";

let heartbeatTimer: any;

export class WebSocketService {
  private wsUrl: string;
  private ws: WebSocket | null;
  public isReconnRequired: boolean;
  private bc: BroadcastChannel;
  private listeners: ((message: string) => void)[];
  private reconnectInterval: number;
  private maxReconnectAttempts: number;
  private reconnectAttempts: number;

  constructor(
    wsUrl: string,
    reconnectInterval = 30000,
    maxReconnectAttempts = Number.MAX_SAFE_INTEGER
  ) {
    this.wsUrl = wsUrl;
    this.ws = null;
    this.isReconnRequired = true;
    this.bc = new BroadcastChannel("ws-channel");
    this.listeners = [];
    this.reconnectInterval = reconnectInterval;
    this.maxReconnectAttempts = maxReconnectAttempts;
    this.reconnectAttempts = 0;

    this.bc.onmessage = (event) => {
      this.notifyListeners(event.data);
    };

    this.connectWebSocket();
  }

  private connectWebSocket() {
    this.ws?.close();
    this.ws = new WebSocket(this.wsUrl);

    this.ws.onopen = () => {
      console.log("Connected to WebSocket");
      this.reconnectAttempts = 0; // Reset the reconnect attempts counter
    };

    this.ws.onmessage = (event) => {
      const message = event.data;
      if (message === "ping") {
        clearTimeout(heartbeatTimer);
        heartbeatTimer = setTimeout(() => {
          console.log("No heartbeat received, connection might be lost.");
          this.ws?.close();
        }, 30000); // Timeout period
      } else {
        this.bc.postMessage(message);
        this.notifyListeners(message);
      }
    };

    this.ws.onclose = () => {
      if (!this.isReconnRequired) return;

      console.log("WebSocket closed, attempting to reconnect...");
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        setTimeout(() => this.connectWebSocket(), this.reconnectInterval);
        this.reconnectAttempts++;
      } else {
        console.error("Max reconnect attempts reached");
      }
    };

    this.ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      this.ws?.close();
    };
  }

  sendMessage(message: string) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(message);
    } else {
      console.warn("WebSocket is not open: cannot send message");
    }
  }

  addListener(listener: (message: string) => void) {
    this.listeners.push(listener);
  }

  removeListener(listener: (message: string) => void) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  private notifyListeners(message: string) {
    this.listeners.forEach((listener) => listener(message));
  }

  close() {
    console.log("trying to close the websocket.", this);
    if (this.ws?.readyState === WebSocket.OPEN) {
      this.ws?.close();
    }
  }

  getUrl() {
    return this.wsUrl;
  }
}
