import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type LangSliceType = {
  lang: string;
  languages: LanguageType[];
};

export const langSlice = createSlice({
  name: "lang",
  initialState: {
    lang: "",
    languages: [],
  } as LangSliceType,
  reducers: {
    changeLanguage: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
    },
    loadLanguages: (state, action: PayloadAction<LanguageType[]>) => {
      state.languages = action.payload;
    },
  },
});

export const { changeLanguage, loadLanguages } = langSlice.actions;

export default langSlice.reducer;
