import {
  DefaultUILanguage,
  LanguageMappingFromUI2Backend,
} from "./lang.config";

export function UILanguagesList() {
  const languages = [];
  for (const key in LanguageMappingFromUI2Backend) {
    if (LanguageMappingFromUI2Backend.hasOwnProperty(key)) {
      languages.push(key);
    }
  }
  return languages;
}

export function getMappedBackendQueryLanguage(
  lang: keyof typeof LanguageMappingFromUI2Backend
) {
  return (
    LanguageMappingFromUI2Backend[lang] ||
    LanguageMappingFromUI2Backend[DefaultUILanguage]
  );
}

export type UILanguageType = keyof typeof LanguageMappingFromUI2Backend;
export { DefaultUILanguage };
