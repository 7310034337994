export interface IMessage {
  session_id?: string;
  user_id?: string;
  new_posts?: any[];
  page_refresh_required?: boolean;
  logout_required?: boolean;
  cart_info?: ShoppingCartItemType[];
}

export class MessageParser {
  session_id?: string;
  user_id?: string;
  new_posts?: any[];
  page_refresh_required?: boolean;
  logout_required?: boolean;
  cart_info?: ShoppingCartItemType[];

  constructor(message: IMessage) {
    this.session_id = message.session_id;
    this.user_id = message.user_id;
    this.new_posts = message.new_posts;
    this.page_refresh_required = message.page_refresh_required;
    this.logout_required = message.logout_required;
    this.cart_info = message.cart_info;
  }
  serialize() {
    return {
      session_id: this.session_id,
      user_id: this.user_id,
      new_posts: this.new_posts,
      page_refresh_required: this.page_refresh_required,
      logout_required: this.logout_required,
      cart_info: this.cart_info,
    };
  }

  static parse(message: IMessage): MessageParser {
    return new MessageParser(message);
  }

  // Extend with additional parsing methods as needed
}
